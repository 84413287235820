@font-face {
  font-family: 'Myriad-Pro';
  font-weight: 400;
  src: url('./assets/fonts/Myriad-Pro.ttf') format('truetype');
}
@font-face {
  font-family: 'Myriad-Pro';
  font-weight: 600;
  src: url('./assets/fonts/Myriad-Pro-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'Myriad-Pro';
  font-weight: 700;
  src: url('./assets/fonts/Myriad-Pro-Bold.ttf') format('truetype');
}
